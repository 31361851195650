import React, { useState, useEffect } from 'react';
import style from './_ScheduleCard.module.scss';
import { invert } from 'lodash';
import { Slider } from 'antd';
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';
import dirtyIcon from '../../assets/icons/stain-1.png';
import inProgressIcon from '../../assets/icons/clean-1.png';
import cleanIcon from '../../assets/icons/shine-1.png';
import dirtyIconOrange from '../../assets/icons/stain-3-orange.png';
import inProgressIconOrange from '../../assets/icons/clean-3-orange.png';
import cleanIconOrange from '../../assets/icons/shine-3-orange.png';
import L from '../../common/get-localized-string';

function ScheduleCard(props) {
  const {
    unit,
    backToBack,
    handleShowNotes,
    hidden,
    admin,
    occupied,
    isToday,
    language,
  } = props;
  
  let checkedIn = false;
  if (unit.checked_in && unit.checkInReservation) {
    // hide the slider and add the checked_in indicator
    checkedIn = true;
  }

  if (occupied && isToday) {
    return null;
  }

  // if it's not today, hide units that only have check-ins or have nothing happening
  if ((!isToday && unit.checkInReservation && !unit.checkOutReservation) || (!isToday && !unit.checkInReservation && !unit.checkOutReservation)) {
    return null;
  }

  const statusAndSliderValue = {
    'dirty': 1,
    'in progress': 2,
    'clean': 3,
  };

  const setCleaningStatus = window.firebase.functions().httpsCallable('setCleaningStatus');
  const [ sliderStatus, setSliderStatus ] = useState(statusAndSliderValue[unit.cleaning_status]);
  const [ toggleDetails, setToggleDetails ] = useState(false);

  const dirtyImage = sliderStatus === 1 ? dirtyIconOrange : dirtyIcon;
  const inProgressImage = sliderStatus === 2 ? inProgressIconOrange : inProgressIcon;
  const cleanImage = sliderStatus === 3 ? cleanIconOrange : cleanIcon;

  useEffect(() => {
    setSliderStatus(statusAndSliderValue[unit.cleaning_status]);
  }, [ unit ]);

  const renderButton = (admin, checkOut, checkIn) => {
    if (checkOut) {
      return <button className={style.dropdown_button} onClick={() => setToggleDetails(!toggleDetails)}>{handleIconDirection(toggleDetails)}</button>;
    }
    if (checkIn && admin) {
      return <button className={style.dropdown_button} onClick={() => setToggleDetails(!toggleDetails)}>{handleIconDirection(toggleDetails)}</button>;
    }
  };

  const renderCheckInDetails = (admin, checkIn) => {
    if (admin && checkIn) {
      return <div>
        <p>{L('checkInGuestContact', language)}: {unit.checkInReservation.guest.first_name} {unit.checkInReservation.guest.last_name} - <button className={style.phone_button} onClick={ () => { copy(unit.checkInReservation.guest.phone); alert('Phone Number Copied'); }}>{unit.checkInReservation.guest.phone}</button></p>
        {unit.name === 'FRV 182' || unit.name === 'FRV 183' ? <p>{L('noLock', language)}.</p> : <p>{L('checkInDoorPin', language)}: {unit.checkInReservation.pin}</p> }
      </div>;
    }
  };

  const renderCheckOutDetails = (admin, checkOut) => {
    if (admin && checkOut) {
      return <div>
        <p>{L('checkOutGuestContact', language)}: {unit.checkOutReservation.guest.first_name} {unit.checkOutReservation.guest.last_name} - <button className={style.phone_button} onClick={ () => { copy(unit.checkOutReservation.guest.phone); alert('Phone Number Copied'); }}>{unit.checkOutReservation.guest.phone}</button></p>
        {unit.name === 'FRV 182' || unit.name === 'FRV 183' ? <p>{L('noLock', language)}.</p> : <p>{L('checkOutDoorPin', language)}: {unit.checkOutReservation.pin}</p> }
      </div>;
    }
  };

  const handleIconDirection = (toggleDetails) => {
    if (toggleDetails) {
      return <p className={style.details_header}>{L('details', language)}<span className="k-icon k-i-arrow-chevron-down" ></span ></p>;
    }
    return <p className={style.details_header}>{L('details', language)}<span className=" k-icon k-i-arrow-chevron-right" ></span ></p>;
  };

  const handleSliderChange = (event) => {
    if (event === 3) {
      return handleShowNotes(unit);
    }
    setSliderStatus(event);
    const newCleaningStatus = invert(statusAndSliderValue)[event];
    const newCleaningDetails = {
      cleaning_status: newCleaningStatus,
      property_id: unit.property_id.toString(),
    };
    setCleaningStatus(newCleaningDetails);
  };

  const marks = {
    1: <span style={{ fontSize: '11px' }}><img src={dirtyImage} alt="Unit is dirty" width={20} height={20} style={{ paddingRight: '2px' }}/>{L('dirty', language)}</span>,
    2: <span style={{ fontSize: '11px' }}><img src={inProgressImage} alt="Unit is in progress" width={20} height={20} style={{ paddingRight: '2px' }}/>{L('inProgress', language)}</span>,
    3: <span style={{ fontSize: '11px' }}><img src={cleanImage} alt="Unit is clean" width={20} height={20} style={{ paddingRight: '2px' }}/>{L('clean', language)}</span>,
  };

  let checkoutTime = '-';
  let checkinTime = '-';
  let formatString = 'ha';

  if (unit.checkOutReservation) {
    const checkoutMinutes = moment(new Date(unit.checkOutReservation.checkout_time).toISOString().split('', 16).join('')).format('mm');
    if (checkoutMinutes !== '00') {
      formatString = 'h:mma';
    }
    checkoutTime = moment(new Date(unit.checkOutReservation.checkout_time).toISOString().split('', 16).join('')).format(formatString);
    if (checkoutTime === '4pm') {
      checkoutTime = '10am';
    }
  }

  if (unit.checkInReservation) {
    const checkinMinutes = moment(new Date(unit.checkInReservation.checkout_time).toISOString().split('', 16).join('')).format('mm');
    if (checkinMinutes !== '00') {
      formatString = 'h:mma';
    }
    checkinTime = moment(new Date(unit.checkInReservation.checkin_time).toISOString().split('', 16).join('')).format(formatString);
    if (checkinTime === '10pm') {
      checkinTime = '4pm';
    }
  }

  return (
    <div className={style.unit_card} key={unit.property_id}>
      <div className={style.unit_name}>
        <h2 className={style.unit_name_title}>{unit.name} </h2>
        <span className={backToBack ? style.unit_backtoback_yes : style.unit_backtoback_no}>!!</span>
        <p className={style.unit_backtoback_yes}>{checkedIn && isToday ? <span className={'k-icon k-i-check'} style={{ color: '#406c4a', fontSize: '2rem' }}></span> : null}</p>
        <div className={style.times_container}>
          <h4 className={style.time_label} style={(unit.cleaning_status === 'clean' || checkedIn) && isToday ? { color: 'white' } : null}>{L('out', language)}</h4>
          <h4 className={style.time_label} style={!unit.checkInReservation && isToday ? { color: 'white' } : null}>{L('in', language)}</h4>
          <h4 className={style.time} style={(unit.cleaning_status === 'clean' || checkedIn) && isToday ? { color: 'white' } : null}>{checkoutTime}</h4>
          <h4 className={style.time} style={!unit.checkInReservation && isToday ? { color: 'white' } : null}>{checkinTime}</h4>
        </div>
      </div>
      <Slider className={checkedIn || (!checkedIn && unit.cleaning_status === 'clean') ? style.slider_hidden : style.slider } style={isToday ? null : { display: 'none' }} dots={true} marks={marks} step={1} min={1} max={3} value={sliderStatus} onChange={(e) => handleSliderChange(e)}/>
      <h3 className={style.awaiting_checkin} style={isToday && unit.checkInReservation && !checkedIn && unit.cleaning_status === 'clean' ? null : { display: 'none' }}>Awaiting Guest Check-In</h3>
      <h3 className={style.awaiting_checkin} style={isToday && !unit.checkInReservation && !unit.checkOutReservation && unit.cleaning_status === 'clean' ? null : { display: 'none' }}>Clean</h3>
      <h3 className={style.awaiting_checkin} style={isToday && !unit.checkInReservation && unit.checkOutReservation && unit.cleaning_status === 'clean' ? null : { display: 'none' }}>Cleaned Today</h3>
      {renderButton(admin, unit.checkOutReservation, unit.checkInReservation)}
      { toggleDetails &&
      <div>
        <p>{L('address', language)}: {unit.address.display}</p>
        {unit.checkOutReservation ?
          <div>
            <p>{L('numberOfGuests', language)}: {unit.checkOutReservation.guests}</p>
            <p>{L('reservationDays', language)}: {unit.checkOutReservation.nights}</p>
          </div> : null
        }
        {renderCheckInDetails(admin, unit.checkInReservation)}
        {renderCheckOutDetails(admin, unit.checkOutReservation)}
      </div>
      }
      {isToday ? <p className={style.occupied} style={!isToday ? null : { display: 'none' }}>{occupied ? 'Occupied Today' : null}</p> : null }
    </div>
  );
}

ScheduleCard.propTypes = {

};

export default ScheduleCard;
