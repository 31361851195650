const USER_ROLE = {
  admin: null,
  cleaner: null,
  owner: null,
};

/* mirror keys/values */
Object.keys(USER_ROLE).forEach(role => {
  USER_ROLE[role] = role;
});

export default USER_ROLE;
