// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Users-module__container___2V5mF{display:flex;flex-direction:column;flex:1 1}._Users-module__container___2V5mF ._Users-module__header_container___3uV0q{display:flex;padding:30px 0}._Users-module__container___2V5mF ._Users-module__header_container___3uV0q h1{align-self:center;margin:0;text-align:center;font-size:18px}._Users-module__table_button___mQFTr{width:30px;height:100%;font-size:12px;font-size:.75rem;background:none;border:none}\n", ""]);
// Exports
exports.locals = {
	"container": "_Users-module__container___2V5mF",
	"header_container": "_Users-module__header_container___3uV0q",
	"table_button": "_Users-module__table_button___mQFTr"
};
module.exports = exports;
