import React from 'react';
import style from './_Calendar.module.scss';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Icon, Modal, Button } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import CustomCalendar from './KendoCustomCalendar';
import L from '../../../common/get-localized-string';

function CalendarView(props) {
  const {
    reservations,
    loading,
    showDetailModal,
    showB2bDetailModal,
    showModal,
    handleModalClose,
    selectedDay,
    selectedUnit,
    selectedRegion,
    setSelectedRegion,
    setLoading,
    month,
    setMonth,
    userRole,
    userRegion,
    language,
    refreshCalendar,
  } = props;
  if (loading || reservations === []) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon spin type="loading" style={{ fontSize: 100, color: '#406c4a' }} />
      </div>
    );
  }

  const checkIfEmpty = () => {
    let resCounter = 0;
    for (const unit in reservations) {
      for (const day in reservations[unit]) {
        if (
          reservations[unit][day]['reservations'].length > 0 ||
          reservations[unit][day].reservationStarting ||
          reservations[unit][day].reservationEnding
        ) {
          resCounter++;
        }
      }
    }
    if (resCounter === 0) {
      return false;
    }
    return true;
  };

  const reservationData = reservations ? reservations : null;
  const estesUnits = [
    '374188',
    '374190',
    '374192',
    '374194',
  ];

  const floridaUnits = [ '374200', '608070', '374112', '374113', '374114' ];
  const shortHandNames = {
    '374188': `${L('unit', language)} 1`,
    '374190': `${L('unit', language)} 6`,
    '374192': `${L('unit', language)} 8`,
    '374194': `${L('unit', language)} 9`,
    '374200': '204',
    '608070': 'P104',
    '374112': 'F206',
    '374113': 'F401',
    '374114': 'N301',
  };
  const selectedMonth = moment(month);
  const parsedMonth = moment(month).format('MM');
  const parsedYear = moment(month).format('YYYY');
  const selectedMonthDays = selectedMonth.daysInMonth();
  let dayArray = [];
  for (let i = 1; i <= selectedMonthDays; i++) {
    dayArray.push({
      date: i,
      day_abbr: moment(`${parsedYear}-${parsedMonth}-${i < 10 ? '0' + i.toString() : i}`).format('ddd'),
    });
  }

  let styleClass = '';
  const stylingCell = (unit, day) => {
    const reservationObj = reservationData[unit][day];
    if (reservationObj.reservationEnding && reservationObj.reservationStarting) {
      return (styleClass = style.back_to_back);
    }
    // TODO: figure out how to tell if res code is offbook
    // else if (reservationObj.reservationStarting && reservationObj[reservationObj.reservations[0]].channel === 'offbook') { 
    //   return (styleClass = style.off_book_in);
    // } else if (reservationObj.reservationEnding && reservationObj[reservationObj.reservations[0]].channel === 'offbook') { 
    //   return (styleClass = style.off_book_out);
    // } 
    else if (reservationObj.reservationStarting) {
      return (styleClass = style.checkin_today);
    } else if (reservationObj.reservationEnding) {
      return (styleClass = style.checkout_today);
    } else if (
      reservationObj.isOccupied
      // &&
      // reservationObj[reservationObj.reservations[0]].channel !== 'offbook'
    ) {
      return (styleClass = style.occupied);
    } 
    // else if (
    //   reservationObj.reservations.length === 1 
    //   // &&
    //   // reservationObj[reservationObj.reservations[0]].channel === 'offbook'
    // ) {
    //   return (styleClass = style.off_book);
    // } 
  };

  const currentDayValue = moment().valueOf();
  const currentDate = moment().format('YYYY-MM-DD');
  
  const updateAdditionalStyling = (unit, day) => {
    let opacityStyle = { opacity: '1' };
    let usableRes = {};
    if (reservationData[unit][day].isOccupied) {
      usableRes = reservationData[unit][day].reservations.occupiedRes;
    } else if (reservationData[unit][day].reservationEnding && reservationData[unit][day].reservationStarting) {
      usableRes = reservationData[unit][day].reservations.checkInRes;
    } else if (reservationData[unit][day].reservationEnding) {
      usableRes = reservationData[unit][day].reservations.checkOutRes;
    } else if (reservationData[unit][day].reservationStarting) {
      usableRes = reservationData[unit][day].reservations.checkInRes;
    } 
    if (Object.keys(usableRes).length) {
      const checkoutTime =
        moment(usableRes.checkoutTime).valueOf();
      const checkoutDate = moment(usableRes.checkoutTime).format('YYYY-MM-DD');
      if (checkoutTime < currentDayValue && checkoutDate !== currentDate) {
        opacityStyle = { opacity: '.2', height: '0' };
      }
    }
    if (reservations[unit][day].isMaintenanceEvent) {
      opacityStyle.border = '4px solid red';
    }
    
    return opacityStyle;
  };

  const renderModal = () => {
    if (!selectedDay || !selectedUnit || !showDetailModal) {
      return null;
    }
    let usableRes = {};
    if (reservationData[selectedUnit][selectedDay].isOccupied) {
      usableRes = reservationData[selectedUnit][selectedDay].reservations.occupiedRes;
    } else if (reservationData[selectedUnit][selectedDay].reservationEnding) {
      usableRes = reservationData[selectedUnit][selectedDay].reservations.checkOutRes;
    } else if (reservationData[selectedUnit][selectedDay].reservationStarting) {
      usableRes = reservationData[selectedUnit][selectedDay].reservations.checkInRes;
    }
    if (!usableRes.guestData) {
      return null;
    }
    // TODO: add homeaway/airbnb logo based on rescode if possible
    const guestName = usableRes.guestData.name;
    const rawPhoneNumber = usableRes.guestData.phone;
    const provider = usableRes.guestData.resCode.slice(0, 2);
    return (
      <Modal
        title={L('reservationDetails', language)}
        visible={showDetailModal}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <p style={{ textDecoration: 'underline' }}>
          {`${moment(usableRes.checkinTime).format('MMM Do')} to ${moment(usableRes.checkoutTime).format('MMM Do')}`}
          {provider !== 'HA' ? ' (AirBnb)' : ' (VRBO)'}
        </p>
        <p>{`${guestName}`}</p>
        <p>
          <button
            className={style.phone_button}
            onClick={() => {
              copy(rawPhoneNumber);
              alert('Phone Number Copied');
            }}
          >
            {rawPhoneNumber}
          </button>
        </p>
        <p style={{ color: 'red' }}>{reservationData[selectedUnit][selectedDay].maintenanceEventDesc ? `Maintenance Event: ${reservationData[selectedUnit][selectedDay].maintenanceEventDesc}` : null }</p>
      </Modal>
    );
  };

  const renderBacktobackModal = () => {
    if (!selectedDay || !selectedUnit || !showB2bDetailModal) {
      return null;
    }
    const incomingRes = reservationData[selectedUnit][selectedDay].reservations.checkInRes;
    const outgoingRes = reservationData[selectedUnit][selectedDay].reservations.checkOutRes;
    if (!incomingRes.guestData) {
      return null;
    }
    return (
      <Modal
        title={L('reservationDetails', language)}
        visible={showB2bDetailModal}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <div style={{ border: '2px solid green', borderRadius: '2%', padding: '2rem', marginBottom: '2rem' }}>
          <p>Outgoing Guest</p>
          <p style={{ textDecoration: 'underline' }}>
            {`${moment(outgoingRes.checkinTime).format('MMM Do')} to ${moment(outgoingRes.checkoutTime).format('MMM Do')}`}
            {outgoingRes.guestData.resCode.slice(0, 2) !== 'HA' ? ' (AirBnb)' : ' (VRBO)'}
          </p>
          <p>
            <button
              className={style.phone_button}
              onClick={() => {
                copy(outgoingRes.guestData.phone);
                alert('Phone Number Copied');
              }}
            >
              {outgoingRes.guestData.name}
              <br />
              {outgoingRes.guestData.phone}
            </button>
          </p>
        </div>
        <div style={{ border: '2px solid green', borderRadius: '2%', padding: '2rem', marginBottom: '2rem' }}>
          <p>Incoming Guest</p>
          <p style={{ textDecoration: 'underline' }}>
            {`${moment(incomingRes.checkinTime).format('MMM Do')} to ${moment(incomingRes.checkoutTime).format('MMM Do')}`}
            {incomingRes.guestData.resCode.slice(0, 2) !== 'HA' ? ' (AirBnb)' : ' (VRBO)'}
          </p>
          <p>
            <button
              className={style.phone_button}
              onClick={() => {
                copy(incomingRes.guestData.phone);
                alert('Phone Number Copied');
              }}
            >
              {incomingRes.guestData.name}
              <br />
              {incomingRes.guestData.phone}
            </button>
          </p>
        </div>
      </Modal>
    );
  };

  const renderTableCells = () => {
    switch (selectedRegion) {
      case 'Florida':
        return floridaUnits;
      case 'Estes':
        return estesUnits;
    }
    return [];
  };

  const handleDateChange = (e) => {
    setLoading(true);
    setMonth(new Date(e.target.value).toISOString().split('T')[0]);
  };

  const handleRegionClick = (region) => {
    setLoading(true);
    setSelectedRegion(region);
  };

  if (!checkIfEmpty()) {
    return (
      <div style={{ margin: '2%' }}>
        <Button
          className={
            selectedRegion === 'Estes'
              ? style.active_region_btn
              : style.region_btn
          }
          style={ userRole !== 'admin' && userRegion === 'Florida' ? { display: 'none' } : { margin: '2%' }}
          onClick={() => handleRegionClick('Estes')}
        >
          Estes
        </Button>
        <Button
          className={
            selectedRegion === 'Florida'
              ? style.active_region_btn
              : style.region_btn
          }
          style={ userRole !== 'admin' && userRegion !== 'Florida' ? { display: 'none' } : { margin: '2%' }}
          onClick={() => handleRegionClick('Florida')}
        >
          Florida
        </Button>
        <DatePicker
          format={'MMMM yyyy'}
          defaultValue={new Date()}
          value={new Date(month)}
          calendar={CustomCalendar}
          onChange={handleDateChange}
        />
        <Button onClick={() => refreshCalendar()}  className={style.region_btn} style={{ margin: '2%' }}>Refresh Calendar</Button>
        <h2 style={{ margin: '1rem' }}>No Reservations For This Month</h2>
      </div>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '2%' }}>
        <Button
          className={
            selectedRegion === 'Estes'
              ? style.active_region_btn
              : style.region_btn
          }
          style={ userRole !== 'admin' && userRegion === 'Florida' ? { display: 'none' } : { margin: '2%' }}
          onClick={() => handleRegionClick('Estes')}
        >
          Estes
        </Button>
        <Button
          className={
            selectedRegion === 'Florida'
              ? style.active_region_btn
              : style.region_btn
          }
          style={ userRole !== 'admin' && userRegion !== 'Florida' ? { display: 'none' } : { margin: '2%' }}
          onClick={() => handleRegionClick('Florida')}
        >
          Florida
        </Button>
        <DatePicker
          format={'MMMM yyyy'}
          defaultValue={new Date()}
          value={new Date(month)}
          calendar={CustomCalendar}
          onChange={handleDateChange}
        />
        <Button onClick={() => refreshCalendar()}  className={style.region_btn} style={{ margin: '2%' }}>Refresh Calendar</Button>
      </div>
      <table className={style.mainTable}>
        <thead>
          <tr>
            <th>{L('date', language)}</th>
            {renderTableCells().map((unit) => {
              return <th key={unit}>{shortHandNames[unit]}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {dayArray.map((day) => {
            const { date, day_abbr } = day;
            let formattedDay = date;
            if (date < 10) {
              formattedDay = `0${date}`;
            }
            return (
              <tr
                key={date}
                style={
                  moment(`${parsedYear}-${parsedMonth}-${formattedDay}`).isSame(currentDate)
                    ? { border: '3px solid black' }
                    : null
                }
              >
                <td
                  style={{
                    paddingLeft: '.33rem',
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{day_abbr}</span>
                    <span style={{ paddingRight: '.33rem' }}>{date}</span>
                  </div>
                </td>
                {renderTableCells().map((unit) => {
                  if (
                    reservationData && reservationData[unit] && reservationData[date] &&
                      reservationData[unit][date].reservationStarting &&  
                    reservationData[unit][date].reservationStarting &&  
                      reservationData[unit][date].reservationStarting &&  
                      reservationData[unit][date].reservationEnding
                  ) {
                    return (
                      <td
                        key={unit + date}
                        className={stylingCell(unit, date)}
                        onClick={() => showModal(unit, date, true)}
                        style={updateAdditionalStyling(unit, date)}
                      ></td>
                    );
                  }
                  return (
                    <td
                      key={unit + date}
                      className={stylingCell(unit, date)}
                      onClick={() => showModal(unit, date, false)}
                      style={updateAdditionalStyling(unit, date)}
                    ></td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {renderModal()}
      {renderBacktobackModal()}
    </div>
  );
}

export default CalendarView;
