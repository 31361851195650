import React, { useEffect } from 'react';
import Routes from './routes';

function Main(){

  return (
    <main>
      { Routes }
    </main>
  );
}

export default Main;
