import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Icon } from 'antd';
import style from './_CreateOffBook.module.scss';
import InputWrapper from 'components/InputWrapper';

function CreateOffBookView(props) {
  const {
    /* data */
    setLocalDirty,
    processing,
    units,
    endDate,
    startDate,
    /* utils */
    form,
    /* methods */
    onSubmit,
    renderError,
    setStartDate,
    setEndDate,
  } = props;

  return (
    <div className={style.container}>
      <div>
        <h2 className={style.maintenance_header}>Create Off-Books Reservation</h2>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)} >
        <div className={style.formWrapper}>
          <div className={style.column}>
            <InputWrapper
              component={<Input />}
              name="first_name"
              label='First Name'
              form={form}
              placeholder={'First Name'}
              setDirty={setLocalDirty}
            />

            <InputWrapper
              component={<Input />}
              name="last_name"
              label='Last Name'
              form={form}
              placeholder={'Last Name'}
              setDirty={setLocalDirty}
            />
            <InputWrapper
              component={<Input />}
              name="email"
              label='Email'
              form={form}
              placeholder={'Email'}
              setDirty={setLocalDirty}
            />
            <InputWrapper
              component={<Input />}
              name="phone_number"
              label='Phone Number'
              form={form}
              placeholder={'Phone Number'}
              setDirty={setLocalDirty}
            />

            <p className={style.date_label}>Unit</p>  
            <DropDownList
              ref={form.register}
              name="unit"
              form={form}
              data={units}
              style={{ width: '100%' }}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
            {renderError('unit')}


            <p className={style.date_label}>Check-In Date</p>  
            <DatePicker
              ref={form.register}
              name="start_date"
              label='Start Date'
              form={form}
              value={startDate}
              placeholder={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              setDirty={setLocalDirty}
            />
            {renderError('start_date')}

            <p className={style.date_label}>Check-Out Date</p>  
            <DatePicker
              ref={form.register}
              name="end_date"
              label='End Date'
              form={form}
              value={endDate}
              placeholder={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              setDirty={setLocalDirty}
            />
            {renderError('end_date')}

          </div>
        </div>
        {
          processing
            ? <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#002E6D' }}
              />
            </div>
            : null
        }
        <Button
          className={style.save_button}
          primary={true}
          disabled={processing}
        >
          Save
        </Button>
      </form>
    </div>
  );
}

export default CreateOffBookView;