import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .typeError('Email is required')
    .label('Email')
    .required(),
  phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid, enter as XXX-XXX-XXXX')
    .label('Phone Number')
    .required(),
  user_role: yup
    .string()
    .typeError('User Role is required')
    .label('User Role')
    .required(),
  language: yup
    .string()
    .typeError('Language is required')
    .label('Language')
    .required(),
});

export default validationSchema;
