import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .label('First Name')
    .required(),
  last_name: yup
    .string()
    .label('Last Name')
    .required(),
  email: yup
    .string()
    .email()
    .label('Email')
    .required(),
  phone_number: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid, enter as XXX-XXX-XXXX')
    .label('Phone Number')
    .required(),
  unit: yup
    .string()
    .label('Unit')
    .required(),
  start_date: yup
    .date()
    .label('Start Date')
    .required(),
  end_date: yup
    .date()
    .label('End Date')
    .required(),
});

export default validationSchema;
