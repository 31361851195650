import React, { useState } from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

/* components */
import MainPageRouter, { navLinks }  from './_router';
import MainPageHeader from './MainPage.header';
import MainPageSidebar from './MainPage.sidebar';
import createClickLink from 'utils/createClickLink';
import style from './_MainPage.module.scss';

/**
 * Admin container
 * - high level admin logic to be used throughout sub routes
 * - render routes with header, sidebar, footer, etc.
 */
function MainPageContainer(props) {
  const {language} = useSelector((state) => ({
    language: state.language,
  }));

  /* local state */
  const [ sidebarCollapsed, setSidebarCollapsed ] = useState(false);

  /* methods to share */
  const containerMethods = {

    getOpenKeys() {
      return [ props.location.pathname ];
    },

    onSelect({ key }) {
      const {
        history,
        location: {
          pathname,
        },
      } = props;

      if (pathname === key) return;

      if (key.indexOf('http') === 0) {
        createClickLink(key);
        return;
      }

      history.push(key);
    },

  };

  /* methods for internal use */
  const internalMethods = {
    parseNavLinks(links) {
      return links.map(_link => {
        const link = { ..._link };

        /* allow link to set it's own path */
        if (link.setPath) {
          link.setPath(props);
        } else {
          link.path = link.originalPath;
        }

        return link;
      }).filter(link => link);
    },

  };

  const navigationLinks = internalMethods.parseNavLinks(navLinks);

  return (
    <Layout hasSider className={style.container} style={{ minHeight: window.innerHeight, overflow: scroll }}>
      <MainPageSidebar
        {...props}
        navLinks={navigationLinks}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
        getOpenKeys={containerMethods.getOpenKeys}
        onSelect={containerMethods.onSelect}
        language={language}
      />

      <Layout hasSider={false}>

        <MainPageHeader
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />

        <Layout.Content className={style.content}>

          <MainPageRouter
            {...props}
            navLinks={navigationLinks}
          />

        </Layout.Content>

      </Layout>

    </Layout>
  );
}

export default MainPageContainer;
