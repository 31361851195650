import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Icon } from 'antd';
import isEmpty from 'lodash/isEmpty';

/* Components */
import InputWrapper from 'components/InputWrapper';
import style from './_EditUser.module.scss';

function EditUserView(props) {
  const {
    /* data */
    fields,
    setLocalDirty,
    newUser,
    history,
    userRoles,
    language,
    userError,
    createUserError,
    processing,

    /* utils */
    form,

    /* methods */
    onSubmit,
    renderError,
  } = props;

  const regions = [ 'Florida', 'Estes', 'Both' ];

  function header() {
    if (newUser) {
      return (
        <h1 className={style.header_title}>Add New User</h1>
      );
    }

    return (
      <h1 className={style.header_title}>User: {fields.email}</h1>
    );
  }

  if (userError) {
    return (
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/mainpage/users`)}
        > Go Back
        </Button>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
          <p>{userError}</p>
        </div>
      </div>
    );
  }

  // default values won't rerender on update -- need to set defaults from Firebase first
  if (isEmpty(fields)) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }

  let defaultRegionDropDown = 'Region';
  let defaultRoleDropDown = 'User Role';
  let defaultLanguageDropDown = 'Language';
  let dropDownColor = '#6c757d';
  if (!newUser) {
    defaultRegionDropDown = fields.region;
    defaultRoleDropDown = fields.user_role;
    defaultLanguageDropDown = fields.language;
    dropDownColor = '#212529';
  }


  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/mainpage/users`)}
        >
          Go Back
        </Button>
      </div>
      <div>{header()}</div>

      <form onSubmit={form.handleSubmit(onSubmit)} >
        <div className={style.formWrapper}>

          <div className={style.column}>
            <InputWrapper
              component={<Input />}
              name="email"
              label='Email'
              form={form}
              defaultValue={fields.email}
              placeholder={'Email'}
              setDirty={setLocalDirty}
            />

            <InputWrapper
              component={<Input />}
              name="phone_number"
              label='Phone Number'
              form={form}
              defaultValue={fields.phone_number}
              placeholder={'Phone Number'}
              setDirty={setLocalDirty}
            />

            {
              newUser
                ?  <InputWrapper
                  component={<Input />}
                  name="password"
                  label='Password'
                  form={form}
                  placeholder={'Enter password'}
                  setDirty={setLocalDirty}
                />
                : <InputWrapper
                  component={<Input />}
                  name="password"
                  label='Reset Password'
                  form={form}
                  placeholder={'Enter new password to reset'}
                  setDirty={setLocalDirty}
                />
            }

            <div className={style.grid_label}>
              <DropDownList
                ref={form.register}
                name="region"
                label='Region'
                form={form}
                defaultValue={defaultRegionDropDown}
                data={regions}
                style={{ width: '100%', backgroundColor: '#ffff', borderRadius: '4px', color: dropDownColor }}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
              {renderError('region')}
            </div>

            <div className={style.grid_label}>
              <DropDownList
                ref={form.register}
                name="user_role"
                label='User Role'
                form={form}
                defaultValue={defaultRoleDropDown}
                data={userRoles}
                style={{ width: '100%', backgroundColor: '#ffff', borderRadius: '4px', color: dropDownColor }}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
              {renderError('user_role')}
            </div>

            <div className={style.grid_label}>
              <DropDownList
                ref={form.register}
                name="language"
                label='Language'
                form={form}
                defaultValue={defaultLanguageDropDown}
                data={language}
                style={{ width: '100%', backgroundColor: '#ffff', borderRadius: '4px', color: dropDownColor }}
                onChange={() => {
                  setLocalDirty(true);
                }}
              />
              {renderError('language')}
            </div>

          </div>

        </div>

        {
          createUserError
            ? <div className={style.field_error} style={{ textAlign: 'left' }}>{createUserError}</div>
            : null
        }

        {
          processing
            ? <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#002E6D' }}
              />
            </div>
            : null
        }

        <Button
          className={style.save_button}
          primary={true}
          disabled={processing}
        >
          Save
        </Button>
      </form>
    </div>
  );
}

export default EditUserView;
