import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import style from './_Users.module.scss';
import L from '../../../common/get-localized-string';

export default class BrandsTable extends Component {
  constructor (props) {
    super(props);
  }

  rowRender(trElement, dataItem) {
    const orange = { backgroundColor: '#ffd433' };
    const red = { backgroundColor: 'rgb(243, 23, 0, 0.32)' };
    const white = { backgroundColor: '#ffff' };

    if (dataItem.dataItem.customClaims) {
      const trProps = { style: dataItem.dataItem.customClaims.user_role === 'cleaner' ? orange : white };
      return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }
    const trProps = { style: red };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  handleEditButton = ({ dataItem, rowType }) => {
    if (rowType === 'groupHeader') {
      return null;
    }
    return (
      <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        <button
          className={`${style.table_button}`}
          title={'Event Edit'}
          onClick={() => {
            this.props.handleEditUser(dataItem.uid);
          }}
        >
          <span className='k-icon k-i-edit'></span >
        </button>
      </td>
    );
  };

  handleRoleIcon = ({ dataItem, rowType }) => {
    if (rowType === 'groupHeader') {
      return null;
    }
    const { region } = dataItem.customClaims;

    if (region === 'Estes') {
      return (<td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        <span role="img" aria-label="tree" style={{ fontSize: '25px' }}>🌲</span>
      </td>);
    } else if (region === 'Florida') {
      return (<td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        <span role="img" aria-label="sun" style={{ fontSize: '25px' }}>🏖️</span>
      </td>);
    }
    return (<td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
      <span role="img" aria-label="x" style={{ fontSize: '25px' }}>🏖️🌲</span>
    </td>);
  };

  render() {
    const {
      users,
      language
    } = this.props;
    const userData = users.data ? users.data.users : null;

    return (
      <Grid
        style={{ width: '100%' }}
        resizable={true}
        data={userData}
        rowRender={this.rowRender}
      >
        <Column
          field="email"
          title={L('emailAddress', language)}
        />
        <Column
          field="customClaims.phone_number"
          title={L('phoneNumber', language)}
        />
        <Column
          field="customClaims.user_role"
          title={L('userRole', language)}
        />
        <Column
          field="customClaims.region"
          title={L('region', language)}
          cell={this.handleRoleIcon}
          width={'75%'}
        />
        <Column
          field="edit"
          title={L('edit', language)}
          cell={this.handleEditButton}
          width={'75%'}
          style={{ textAlign: 'center' }}
        />
      </Grid>

    );
  }
}
