import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Layout,
  Icon,
  Menu,
  Button,
} from 'antd';

/* components */
import style from './_MainPage.module.scss';
import checkMobile from 'utils/checkMobile';
import L from '../../common/get-localized-string';

/* data management */
import { signOut } from 'state/actions';
import createDispatchedActions from 'utils/createDispatchedActions';

function MainPageSidebar(props) {
  /* redux actions, wrapped in dispatch */
  const actions = createDispatchedActions(signOut);

  const {
    /* data */
    user,
    sidebarCollapsed,
    navLinks,
    language,

    /* actions */
    setSidebarCollapsed,
    getOpenKeys,
    onSelect,
  } = props;

  function renderIcon(icon) {
    if (!icon) return null;
    return <Icon type={icon} style={{ fontSize: 17 }}/>;
  }

  return (
    <Layout.Sider
      breakpoint="xl"
      collapsedWidth="0"
      defaultCollapsed={true}
      collapsed={sidebarCollapsed}
      onCollapse={setSidebarCollapsed}
      className={style.sidebar}
      width={checkMobile() ? '100%' :  250}
    >
      <div className={style.menu_close} style={{ height: 62.5 }} onClick={() => setSidebarCollapsed(true)}>
        <Icon type="close"/>
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={getOpenKeys()}
        selectedKeys={getOpenKeys()}
        onSelect={(item) => {
          onSelect(item);
          checkMobile() && setSidebarCollapsed(true);
        }}
      >

        <Menu.Divider />

        {navLinks.map(navLink => {
          if (navLink.hidden) return null;
          if (navLink.permittedUserRoles && !navLink.permittedUserRoles.includes(user.user_role)) {
            return null;
          }

          return (
            [
              <Menu.Item
                key={navLink.path}
                disabled={navLink.disabled}
                style={{
                  ...navLink.style,
                  height: 50,
                  lineHeight: 3,
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: 17,
                }}
              >
                {renderIcon(navLink.iconLeft)}
                <span>{L(navLink.title, language)}</span>&nbsp;&nbsp;
                {renderIcon(navLink.iconRight)}
              </Menu.Item>,
              <Menu.Divider key={`divider-${navLink.path}`}/>,
            ]
          );
        })}


      </Menu>

      {sidebarCollapsed ? null : (
        <div className={style.signout}>
          <Link replace to="/sign-out">
            <Button
              className={style.signout_btn}
              block
              icon="logout"
              type="warning"
              size="large"
              onClick={actions.signOut}
            >
              {L('signOut', language)}
            </Button>
          </Link>
        </div>
      )}

    </Layout.Sider>
  );
}


MainPageSidebar.propTypes = {
  /* data */
  sidebarCollapsed: PropTypes.bool.isRequired,

  /* actions */
  setSidebarCollapsed: PropTypes.func.isRequired,
  getOpenKeys: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default MainPageSidebar;


