import types from '../types';

export function setReduxLocation(location) {
  console.log('setReduxLocation location', location);
  return function(dispatch) {
    const brandRef = window.firebase.firestore().doc(`brands/${location.brand_id}`);

    brandRef.get()
      .then((brand) => {
        return dispatch({
          type: types.SET_LOCATION,
          payload: {
            ...location,
            brandDetails: {
              ...brand,
            },
          },
        });
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
}

export function clearLocation() {
  return {
    type: types.CLEAR_LOCATION,
    payload: {},
  };
}
