const LOADING_KEY = {
  auth: null,
};

/* mirror keys/values */
Object.keys(LOADING_KEY).forEach(key => {
  LOADING_KEY[key] = key;
});

export default LOADING_KEY;
