import ScheduleMaintenanceView from './ScheduleMaintenance.view';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import validationSchema from './validationSchema';
import { useForm } from 'react-hook-form';
import style from './_ScheduleMaintenance.module.scss';

const addMaintenanceEvent = window.firebase.functions().httpsCallable('addMaintenanceEvent');

function ScheduleMaintenanceContainer(props) {
  const [ processing, setProcessing ] = useState(false);
  const [ newUser, setNewUser ] = useState(false);
  const [ userError, setUserError ] = useState('');
  const [ createUserError, setCreateUserError ] = useState('');
  const [ fields, setFields ] = useState({});
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ chosenDate, setChosenDate ] = useState(new Date());

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  const units = [ 'Unit 1', 'Unit 6', 'Unit 8', 'Unit 9', 'SL 204' ];

  const shortHandNames = {
    'Unit 1': 374188,
    'Unit 6': 374190,
    'Unit 8': 374192,
    'Unit 9': 374194,
    'SL 204': 374200,
  };

  /* container methods */
  const methods = {
    async onSubmit() {
      setProcessing(true);
      const rawFormData = form.watch();
      const formData = {
        unit: shortHandNames[rawFormData.unit],
        date: moment(rawFormData.date).format('YYYY-MM-DD'),
        description: rawFormData.description,
      };
      const dirty = form.formState.dirty || localDirty;
      if (!dirty) {
        return props.history.replace('/mainpage/schedule_maintenance');
      }
      await addMaintenanceEvent(formData)
        .then((res) => {
          setProcessing(false);
          return props.history.replace('/mainpage/schedule');
        })
        .catch((error) => {
          console.log(error);
        });
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };

  return (
    <ScheduleMaintenanceView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      userError={userError}
      createUserError={createUserError}
      processing={processing}
      chosenDate={chosenDate}
      units={units}
      /* methods */
      onSubmit={methods.onSubmit}
      renderError={methods.renderError}
      setLocalDirty={setLocalDirty}
      setChosenDate={setChosenDate}
    />
  );
}

export default ScheduleMaintenanceContainer;