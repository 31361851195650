import * as yup from 'yup';

const validationSchema = yup.object().shape({
  description: yup
    .string()
    .typeError('A description is required')
    .label('Description')
    .required(),
  unit: yup
    .string()
    .label('Unit')
    .required(),
  date: yup
    .date()
    .label('Date')
    .required(),
});

export default validationSchema;
