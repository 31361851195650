import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from 'antd';

/* Components */
import UsersTable from './UsersTable.component';
import style from './_Users.module.scss';

import L from '../../../common/get-localized-string';

function UsersView(props) {
  const {
    loading,
    users,
    handleNavigate,
    handleEditUser,
    history,
    language,
  } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type="loading"
          style={{ fontSize: 100, color: '#002E6D' }}
        />
      </div>
    );
  }


  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <Button
          primary={false}
          onClick={() => history.push(`/mainpage/users/new`)}
        >
          {L('addNewUser', language)}
        </Button>

      </div>
      <UsersTable
        users={users}
        handleNavigate={handleNavigate}
        handleEditUser={handleEditUser}
        language={language}
      />
    </div>
  );
}

UsersView.propTypes = {
};

export default UsersView;
