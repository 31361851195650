import { combineReducers } from 'redux';
import types from '../types';

import loading from './loading.reducer';
import authenticated from './auth.reducer';
import user from './user.reducer';
import notifications from './notifications.reducer';
import location from './location.reducer';
import language from './language.reducer';

const appReducer =  combineReducers({
  loading,
  authenticated,
  user,
  notifications,
  location,
  language,
});

const rootReducer = (state, action) => {
  let nextState = state;
  if (action.type === types.DESTROY_ALL) {
    nextState = undefined;
  }
  return appReducer(nextState, action);
};

export default rootReducer;
