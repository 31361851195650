import React, { useEffect, useState } from 'react';
import CalendarView from './Calendar.view';
import { useSelector } from 'react-redux';
import moment from 'moment';

function CalendarContainer(props) {
  // redux state
  const state = useSelector((state) => ({
    user: state.user,
  }));

  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  // local state
  const [ reservations, setReservations ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ showDetailModal, setShowDetailModal ] = useState(false);
  const [ showB2bDetailModal, setShowB2bDetailModal ] = useState(false);
  const [ selectedUnit, setSelectedUnit ] = useState(null);
  const [ selectedDay, setSelectedDay ] = useState(null);
  const [ selectedReservationCode, setSelectedReservationCode ] = useState(null);
  const [ selectedRegion, setSelectedRegion ] = useState(
    state.user.user_region !== 'Florida' ? 'Estes' : 'CG'
  );
  const [ month, setMonth ] = useState(moment().format('YYYY-MM-DD'));
  const refreshCalendarData = window.firebase
    .functions()
    .httpsCallable('refreshCalendar');

  const showModal = (unit, day, backToBack) => {
    setShowB2bDetailModal(false);
    setShowDetailModal(false);
    setSelectedUnit(unit);
    setSelectedDay(day);
    if (!backToBack) {
      setShowDetailModal(true);
    } else {
      setShowB2bDetailModal(true);
    }
  };

  const handleModalClose = (e) => {
    setSelectedUnit(null);
    setSelectedDay(null);
    setShowDetailModal(false);
  };

  const refreshCalendar = async () => {
    setLoading(true);
    if (selectedRegion === 'Florida') {
      const slData = await refreshCalendarData({ region: 'SL', monthString: month }).then((data) => {
        return data.data;
      });
      const cgData = await refreshCalendarData({ region: 'CG', monthString: month }).then((data) => {
        return data.data;
      });
      setReservations(Object.assign(slData, cgData));
      setLoading(false);
      return;
    }
    refreshCalendarData({ region: selectedRegion, monthString: month }).then((data) => {
      setReservations(data.data);
      setLoading(false);
    })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const findExemptions = async (rawReservations) => {
    if (Object.keys(rawReservations).length === 0) {
      return;
    }
    await window.firebase.firestore().collectionGroup('units')
      .get()
      .then(async (snapshot) => {
        for (const unitDoc of snapshot.docs) {
          const unit = unitDoc.data();
          if (unit.sdExemptions) {
            unit.sdExemptions.forEach((exemption) => {
              if (moment(month).isSame(moment(exemption.date), 'month') && rawReservations[exemption.unit]) {
                const day = moment(exemption.date).format('DD');
                const splitDay = day.split('');
                if (splitDay[0] === '0') {
                  rawReservations[exemption.unit][splitDay[1]].isMaintenanceEvent = true;
                  rawReservations[exemption.unit][splitDay[1]].maintenanceEventDesc = exemption.description;
                } else if (splitDay[0] !== '0') {
                  rawReservations[exemption.unit][day].isMaintenanceEvent = true;
                  rawReservations[exemption.unit][day].maintenanceEventDesc = exemption.description;
                }
              }
            });
          }
        }
        setReservations(rawReservations);
        setLoading(false);
      });
  };

  useEffect(() => {
    setReservations([]);
    setLoading(true);
    async function getReservations() {
      if (!moment(month).isSame(moment(), 'month')) {
        return refreshCalendar();
      }
      if (selectedRegion === 'Florida') {
        const SLData = await window.firebase.firestore().collection('calendarData')
          .doc('SL')
          .get()
          .then((doc) => {
            if (!doc.exists) {
              ('no doc');
              return;
            }
            return doc.data().data;
          });
        const CGData = await window.firebase.firestore().collection('calendarData')
          .doc('CG')
          .get()
          .then((doc) => {
            if (!doc.exists) {
              console.log('no doc');
              return;
            }
            return doc.data().data;
          });
        findExemptions(Object.assign(SLData, CGData));
        return;
      }
      return await window.firebase.firestore().collection('calendarData')
        .doc(selectedRegion)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log('no doc');
            return;
          }
          findExemptions(doc.data().data);
        })
        .catch((e) => {
          console.log('getMonthGrid error:', e);
          setLoading(false);
        });
    }
    getReservations();
  }, [ month, selectedRegion ]);

  return (
    <CalendarView
      reservations={reservations}
      findExemptions={findExemptions}
      loading={loading}
      showModal={showModal}
      handleModalClose={handleModalClose}
      showDetailModal={showDetailModal}
      showB2bDetailModal={showB2bDetailModal}
      selectedUnit={selectedUnit}
      selectedDay={selectedDay}
      selectedReservationCode={selectedReservationCode}
      selectedRegion={selectedRegion}
      setLoading={setLoading}
      setSelectedRegion={setSelectedRegion}
      month={month}
      setMonth={setMonth}
      userRole={state.user.user_role}
      userRegion={state.user.user_region}
      language={language}
      refreshCalendar={refreshCalendar}
    />
  );
}

export default CalendarContainer;
