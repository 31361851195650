// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Notification-module__notification_container___3O3TU{margin:6px;right:0;bottom:0;align-items:flex-start;flex-wrap:wrap-reverse}\n", ""]);
// Exports
exports.locals = {
	"notification_container": "_Notification-module__notification_container___3O3TU"
};
module.exports = exports;
