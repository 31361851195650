import 'utils/suppressConsoleWarnings';
import 'babel-regenerator-runtime';
import './assets/toImport';
import React from 'react';
import ReactDOM from 'react-dom';
import bugsnagReact from '@bugsnag/plugin-react';
import bugsnagClient from './utils/bugsnag_client';

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');
const rootElement = document.getElementById('root');

// staging
const config = {
  apiKey: process.env.FB_HFR_API_KEY,
  authDomain: process.env.FB_HFR_AUTH_DOMAIN,
  databaseURL: process.env.FB_HFR_DATABASE_URL,
  projectId: process.env.FB_HFR_PROJECT_ID,
  storageBucket: process.env.FB_HFR_STORAGE_BUCKET,
  messagingSenderId: process.env.FB_HFR_MESSAGING_SENDER_ID,
  appId: process.env.FB_HFR_APP_ID,
  measurementId: process.env.FB_HFR_MEASUREMENT_ID,
};

// Initialize Firebase
window.firebase.initializeApp(config);

// point to dev functions
if (process.env.FB_HFR_DATABASE_URL.includes('localhost')) {
  window.firebase.functions().useFunctionsEmulator("http://localhost:5001");
  window.firebase.firestore().settings({
    host: "localhost:8082",
    ssl: false
  });
}

if (module.hot) {
  module.hot.accept('core/Root', () => {
    setTimeout(render, 0);
  });
}

render();

function render() {
  const Root = require('core/Root').default;
  ReactDOM.render(
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>, rootElement);
}
