const LANGUAGE = {
  English: null,
  Spanish: null,

};

/* mirror keys/values */

Object.keys(LANGUAGE).forEach(language => {
  LANGUAGE[language] = language;
});

export default LANGUAGE;