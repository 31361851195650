import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

/* components */
import USER_ROLES from 'definitions/keys/userRole.keys';
import RoleHawk from 'HOC/RoleHawk';

/* routes */
import {
  Schedule,
  Users,
  EditUser,
  Calendar,
  ScheduleMaintenance,
  CreateOffBook,
  QuickView,
} from './lazy_routes';

/**
 * Admin Router
 * -
 *
 * @param props
 */
function AdminRouter(props) {
  function firstAvailableRoute() {
    return props.navLinks.find((navLink) => {
      return (
        !navLink.disabled
        && !navLink.hidden
      );
    });
  }

  function renderWithProps(RouteComponent) {
    return (_props) => (
      <RouteComponent
        {..._props}
        navLinks={props.navLinks}
      />
    );
  }

  function renderRoutes() {
    return props.navLinks.map((navLink) => {
      if (!navLink.Component) return null;
      if (navLink.disabled) return null;

      return (
        <Route
          key={`route-${navLink.path}`}
          exact
          path={navLink.path}
          render={renderWithProps(navLink.Component)}
        />
      );
    });
  }

  return (
    <Switch>
      {renderRoutes()}
      <Route
        render={() => {
          /* Redirect to first available route if Not Found/Available */
          return (
            <Redirect to={firstAvailableRoute().path}/>
          );
        }}
      />
    </Switch>
  );
}

export const navLinks = [
  {
    originalPath: '/mainpage/schedule',
    iconLeft: 'schedule',
    title: 'cleanings',
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.cleaner,
    ],
    Component: RoleHawk(
      Schedule,
      [
        USER_ROLES.admin,
        USER_ROLES.cleaner,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/mainpage/calendar',
    iconLeft: 'calendar',
    title: 'calendar',
    permittedUserRoles: [
      USER_ROLES.admin,
      USER_ROLES.cleaner,
      USER_ROLES.owner,
    ],
    Component: RoleHawk(
      Calendar,
      [
        USER_ROLES.admin,
        USER_ROLES.cleaner,
        USER_ROLES.owner,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/mainpage/quick_view',
    iconLeft: 'rocket',
    title: 'quickView',
    permittedUserRoles: [
      USER_ROLES.admin,
      // USER_ROLES.cleaner,
      // USER_ROLES.owner,
    ],
    Component: RoleHawk(
      QuickView,
      [
        USER_ROLES.admin,
        // USER_ROLES.cleaner,
        // USER_ROLES.owner,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/mainpage/users',
    iconLeft: 'user',
    title: 'users',
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      Users,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    hidden: true,
    originalPath: '/mainpage/users/:user_id',
    iconLeft: null,
    title: null,
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      EditUser,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/mainpage/create_off_book',
    iconLeft: 'form',
    title: 'addReservation',
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      CreateOffBook,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
  {
    originalPath: '/mainpage/schedule_maintenance',
    iconLeft: 'tool',
    title: 'scheduleMaintenance',
    permittedUserRoles: [
      USER_ROLES.admin,
    ],
    Component: RoleHawk(
      ScheduleMaintenance,
      [
        USER_ROLES.admin,
      ]
    ),
    style: {},
    setPath() {
      this.path = this.originalPath;
    },
  },
];


export default AdminRouter;
