import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/HappyFamilyRentals_Logo_Reversed.png';
import style from './_MainPage.module.scss';

import {
  Layout,
  Icon,
} from 'antd';

function MainPageHeader(props) {

  const {
    sidebarCollapsed,
    setSidebarCollapsed,
  } = props;


  function renderMenuClose() {
    if (!sidebarCollapsed) return (
      <div className={style.menu_open}>
                &nbsp;
      </div>
    );
    return (
      <div className={`${style.menu_open} ld_cursor_pointer`} onClick={() => setSidebarCollapsed(false)}>
        <Icon type="menu-unfold" />
      </div>
    );
  }

  return (
    <Layout.Header className={style.header}>

      <div className={style.header_logo}>
        <img src={logo} alt="Happy Family Rentals Logo" height={55} width={125}/>
      </div>

      {renderMenuClose()}

    </Layout.Header>
  );
}

MainPageHeader.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
  setSidebarCollapsed: PropTypes.func.isRequired,
};

export default MainPageHeader;


