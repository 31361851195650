import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

/* Components */
import EditUserView from './EditUser.view';
import validationSchema from './validationSchema';
import defaults from 'formDefaults/user.defaults';
import style from './_EditUser.module.scss';
import USER_ROLES from 'definitions/keys/userRole.keys';
import LANGUAGE from 'definitions/keys/language.keys';

const getUserByID = window.firebase.functions().httpsCallable('getUserByID');
const createUser = window.firebase.functions().httpsCallable('createUser');
const updateCustomUserClaims = window.firebase.functions().httpsCallable('updateCustomUserClaims');
const updateUser = window.firebase.functions().httpsCallable('updateUser');

function EditUserContainer(props) {
  const [ processing, setProcessing ] = useState(false);
  const [ newUser, setNewUser ] = useState(false);
  const [ userError, setUserError ] = useState('');
  const [ createUserError, setCreateUserError ] = useState('');
  const [ fields, setFields ] = useState({});
  const [ localDirty, setLocalDirty ] = useState(false);

  /* local form state */
  const form = useForm({
    validationSchema,
  });

  /* data */
  const {
    match: {
      params: {
        user_id,
      },
    },
  } = props;

  const userRoles = Object.keys(USER_ROLES);
  const language = Object.keys(LANGUAGE);

  useEffect(() => {
    if (user_id === 'new') {
      setNewUser(true);

      return setFields({
        ...fields,
        ...defaults,
      });
    }

    async function getFirebaseUser(uid) {
      await getUserByID(uid)
        .then((data) => {
          if (isEmpty(data) || !data.data) {
            setUserError(`Could not find a record for user_id: ${user_id}`);
          }

          const user = data.data;

          setFields({
            ...fields,
            ...defaults,
            email: user.email,
            user_role: user.customClaims.user_role,
            phone_number: user.customClaims.phone_number,
            region: user.customClaims.region,
            language: user.customClaims.language,
          });
        })
        .catch(e => {
          setUserError(`Something went wrong: ${e}`);
          console.log('could not get Firebase user', e);
        });
    }

    getFirebaseUser(user_id);
  }, [  ]);

  /* container methods */
  const methods = {
    onSubmit() {
      setProcessing(true);
      const formData = form.watch();
      const dirty = form.formState.dirty || localDirty;

      if (!dirty) {
        return props.history.replace('/mainpage/users');
      }

      const thisUser = {
        ...defaults,
        ...formData,
      };
      if (newUser) {
        return createNewUser(thisUser);
      }

      return updateFirebaseUser(user_id, thisUser);
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };

  async function createNewUser(user) {
    await createUser(user)
      .then((newUser) => {
        if (newUser.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not create a new user: ${newUser.data.errorInfo.message}`);
        }
        console.log(`created user ${newUser.data.uid}`);
        return updateFirebaseCustomUserClaims(newUser.data.uid, user);
      });
  }

  async function updateFirebaseCustomUserClaims(uid, user) {
    await updateCustomUserClaims({ uid, ...user })
      .then((updatedUser) => {
        if (!updatedUser.data) {
          console.log(`updated user ${uid}`);
          setProcessing(false);
          return props.history.replace('/mainpage/users');
        }
        if (updatedUser.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not create a new user: ${updatedUser.data.errorInfo.message}`);
        }
      });
  }

  async function updateFirebaseUser(uid, user) {
    await updateCustomUserClaims({ uid, ...user })
      .then(() => {
        return updateUser({ uid, ...user });
      })
      .then((res) => {
        if (res.data.errorInfo) {
          setProcessing(false);
          return setCreateUserError(`Could not update password for user ${uid}: ${res.data.errorInfo.message}`);
        }
        console.log(`updated password ${uid}`);
        setProcessing(false);
        return props.history.replace('/mainpage/users');
      });
  }

  return (
    <EditUserView
      /* data */
      fields={fields}
      form={form}
      history={props.history}
      newUser={newUser}
      userRoles={userRoles}
      language={language}
      userError={userError}
      createUserError={createUserError}
      processing={processing}

      /* methods */
      onSubmit={methods.onSubmit}
      renderError={methods.renderError}
      setLocalDirty={setLocalDirty}
    />
  );
}

export default EditUserContainer;
