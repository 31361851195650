import React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Icon } from 'antd';
import style from './_ScheduleMaintenance.module.scss';
import InputWrapper from 'components/InputWrapper';

function ScheduleMaintenanceView(props) {
  const {
    /* data */
    setLocalDirty,
    processing,
    chosenDate,
    units,
    /* utils */
    form,
    /* methods */
    onSubmit,
    renderError,
    setChosenDate,
  } = props;

  return (
    <div className={style.container}>
      <div>
        <h2 className={style.maintenance_header}>Schedule Maintenance Event</h2>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)} >
        <div className={style.formWrapper}>
          <div className={style.column}>
            <InputWrapper
              component={<Input />}
              name="description"
              label='Description'
              form={form}
              placeholder={'Description'}
              setDirty={setLocalDirty}
            />

            <p className={style.date_label}>Unit</p>  
            <DropDownList
              ref={form.register}
              name="unit"
              form={form}
              data={units}
              style={{ width: '100%' }}
              onChange={() => {
                setLocalDirty(true);
              }}
            />
            {renderError('unit')}


            <p className={style.date_label}>Date of Event</p>  
            <DatePicker
              ref={form.register}
              name="date"
              label='Date'
              form={form}
              defaultValue={chosenDate}
              placeholder={chosenDate}
              value={chosenDate}
              onChange={(e) => setChosenDate(e.target.value)}
              setDirty={setLocalDirty}
            />
            {renderError('date')}

          </div>
        </div>
        {
          processing
            ? <div style={{ width: '100%', textAlign: 'center', paddingTop: 100 }}>
              <Icon
                spin
                type="loading"
                style={{ fontSize: 20, color: '#002E6D' }}
              />
            </div>
            : null
        }
        <Button
          className={style.save_button}
          primary={true}
          disabled={processing}
        >
          Save
        </Button>
      </form>
    </div>
  );
}

export default ScheduleMaintenanceView;