import CreateOffBookView from './CreateOffBook.view';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import validationSchema from './validationSchema';
import { useForm } from 'react-hook-form';
import style from './_CreateOffBook.module.scss';

// const addMaintenanceEvent = window.firebase.functions().httpsCallable('addMaintenanceEvent');

function CreateOffBookContainer(props) {
  const [ processing, setProcessing ] = useState(false);
  const [ newUser, setNewUser ] = useState(false);
  const [ userError, setUserError ] = useState('');
  const [ createUserError, setCreateUserError ] = useState('');
  const [ fields, setFields ] = useState({});
  const [ localDirty, setLocalDirty ] = useState(false);
  const [ startDate, setStartDate ] = useState(new Date());
  const [ endDate, setEndDate ] = useState(new Date(moment().add(1, 'week')));


  /* local form state */
  const form = useForm({
    validationSchema,
  });

  const units = [ 'Unit 1', 'Unit 6', 'Unit 8', 'Unit 9', 'SL 204' ];

  const shortHandNames = {
    'Unit 1': 374188,
    'Unit 6': 374190,
    'Unit 8': 374192,
    'Unit 9': 374194,
    'SL 204': 374200,
  };

  /* container methods */
  const methods = {
    async onSubmit() {
      setProcessing(true);
      const rawFormData = form.watch();
      const generatedResObj = {
        code: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        channel: 'offbook',
        sent_at: Date.now(),
        status: 'accepted',
        start_date: moment(rawFormData.start_date).format('YYYY-MM-DD'),
        end_date: moment(rawFormData.end_date).format('YYYY-MM-DD'),
        checkin_time: new Date(moment(rawFormData.start_date).set('hour', 16).set('minute', 0).valueOf()).toISOString(),
        checkout_time: new Date(moment(rawFormData.end_date).set('hour', 10).set('minute', 0).valueOf()).toISOString(),
        guest: {
          email: rawFormData.email,
          first_name: rawFormData.first_name,
          last_name: rawFormData.last_name,
          phone: rawFormData.phone_number,
        },
        listing: {
          property_id: shortHandNames[rawFormData.unit],
        },
      };      

      const dirty = form.formState.dirty || localDirty;
      if (!dirty) {
        return props.history.replace('/mainpage/create_off_book');
      }
      return window.firebase.firestore().collection('smartBNBWebhookLogs')
        .add(generatedResObj)
        .then((ref) => {
          console.log('SUCCESS', ref);
          setProcessing(false);
          return props.history.replace('/mainpage/schedule');
        });
    },

    renderError(name) {
      if (form.errors[name]) {
        return (
          <span className={style.field_error}>{form.errors[name].message}</span>
        );
      }
      return (
        <span className={style.field_error}>&nbsp;</span>
      );
    },
  };

  return (
    <CreateOffBookView
      form={form}
      processing={processing}
      units={units}
      onSubmit={methods.onSubmit}
      renderError={methods.renderError}
      setLocalDirty={setLocalDirty}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  );
}

export default CreateOffBookContainer;