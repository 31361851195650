import QuickViewView from './QuickView.view';
import React, { useEffect, useState } from 'react';

function QuickViewContainer() {
  const returnHFRData = window.firebase.functions().httpsCallable('returnHFRData');
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ unitsData, setUnitsData ] = useState([]);
  const unitsCollection = window.firebase.firestore().collection('units');
  useEffect(() => {
    const getHFRData = async () => {
      setLoading(true);
      return returnHFRData().then((data) => {
        setUnitsData(data.data);
        setLoading(false);
      })
        .catch((e) => {
          setLoading(false);
          setError(true);
          console.log('error getting units data:', e);
          return;
        });
    };
    // get data the first time and show loading
    getHFRData();
    // listen for changes in cleaning/checked_in status and live reload
    const unsubscribe = unitsCollection.onSnapshot(() => {
      return returnHFRData().then((data) => {
        setUnitsData(data.data);
      })
        .catch((e) => {
          setLoading(false);
          setError(true);
          console.log('error getting units data:', e);
          return;
        });
    });

    return () => {
      // unsub firestore listener 
      unsubscribe();
    };
  }, [ ]);

  return (
    <QuickViewView
      loading={loading}
      unitsData={unitsData}
      error={error}
    />
  );
}

export default QuickViewContainer;
