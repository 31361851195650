import types from '../types';

const INITIAL_STATE = 'en';

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(
  state = INITIAL_STATE,
  action = INITIAL_ACTION
) {
  if (action.type === types.SET_LANGUAGE) {
    return action.payload;
  }
  return state;
}
