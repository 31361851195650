import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/* Components */
import UsersView from './Users.view';

function UsersContainer(props) {

  const {language} = useSelector((state) => ({
    language: state.language,
  }));

  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const getUsers = window.firebase.functions().httpsCallable('getUsers');

  useEffect(() => {
    async function getFirebaseUsers() {
      await getUsers()
        .then((data) => {
          setLoading(false);
          setUsers(data);
        })
        .catch(e => {
          setLoading(false);
          console.log('could not get Firebase users', e);
        });
    }

    getFirebaseUsers();
  }, [  ]);

  /* container methods */
  const methods = {
    handleNavigate(param) {
      return props.history.push(`/mainpage/users/${param}`);
    },
    handleEditUser (user_id) {
      return props.history.push(`/mainpage/users/${user_id}`);
    },
  };


  return (
    <UsersView
      loading={loading}
      users={users}
      handleNavigate={methods.handleNavigate}
      handleEditUser={methods.handleEditUser}
      history={props.history}
      language={language}
    />
  );
}

export default UsersContainer;
