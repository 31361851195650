import React from 'react';
import './QuickView.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Icon } from 'antd';
import checkIn from '../../../assets/quickViewIcons/checkIn.png';
import awaiting from '../../../assets/quickViewIcons/awaiting.png';
import backToBack from '../../../assets/quickViewIcons/backToBack.png';
import checkedIn from '../../../assets/quickViewIcons/checkedIn.png';
import checkOut from '../../../assets/quickViewIcons/checkOut.png';
import clean from '../../../assets/quickViewIcons/clean.png';
import dirty from '../../../assets/quickViewIcons/dirty.png';
import inProgress from '../../../assets/quickViewIcons/inProgress.png';
import staying from '../../../assets/quickViewIcons/staying.png';
import vacant from '../../../assets/quickViewIcons/vacant.png';

function QuickViewView(props) {
  const { loading, unitsData, error } = props;
  const estesUnits = [
    374188,
    374190,
    374192,
    374194,
  ];
  const slUnits = [ 374200, 608070, 374112, 374113 ];

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon spin type='loading' style={{ fontSize: 100, color: '#406c4a' }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className='container'>
        <h3>
          There was an error fetching the units data. Please reload the page or
          contact the development team.
        </h3>
      </div>
    );
  }

  const dailyStatusCell = (dataItem) => {
    const unitData = dataItem.dataItem;
    const renderStatus = () => {
      if (unitData.backToBack) {
        return  <img src={backToBack} className='icon' alt='back to back icon'/>;
      } else if (unitData.checkInReservation) {
        return <img src={checkIn} className='icon' alt='check in icon'/>;
      } else if (unitData.checkOutReservation) {
        return <img src={checkOut} className='icon' alt='check out icon'/>;
      } else if (!unitData.checkedIn) {
        return <img src={vacant} className='icon' alt='vacant icon'/>;
      } else if (unitData.checkedIn) {
        return <img src={staying} className='icon' alt='staying icon'/>;
      }
      return '?';
    };
    return (
      <td>
        {renderStatus()}
      </td>
    );
  };

  const arrivalCell = (dataItem) => {
    const unitData = dataItem.dataItem;
    const renderIcon = () => {
      if (unitData.checkInReservation && unitData.checkedIn) {
        return <img src={checkedIn} className='icon' alt='vacant icon'/>;
      } else if (unitData.checkInReservation && !unitData.checkedIn) {
        return <img src={awaiting} className='icon' alt='vacant icon'/>;
      } 
      return null;
    };
    return (
      <td>
        {renderIcon()}
      </td>
    );
  };
  const cleaningCell = (dataItem) => {
    const unitData = dataItem.dataItem;
    const staying = unitData.checkedIn;
    const renderStatus = () => {
      if (unitData.cleaningStatus === 'clean' && !staying) {
        return <img src={clean} className='icon' alt='clean icon'/>;
      } else if (unitData.cleaningStatus === 'dirty' && !staying) {
        return <img src={dirty} className='icon' alt='dirty icon'/>;
      } else if ((unitData.backToBack || unitData.checkInReservation) && unitData.cleaningStatus === 'clean' && !staying) {
        return <img src={clean} className='icon' alt='clean icon'/>;
      } else if ((unitData.backToBack || unitData.checkInReservation) && unitData.cleaningStatus === 'dirty' && staying) {
        return <img src={clean} className='icon' alt='clean icon'/>;
      } else if (staying) {
        return null;
      } else if (unitData.cleaningStatus === 'in progress') {
        return <img src={inProgress} className='icon' alt='in progress icon'/>;
      }
      return '?';
    };
    return (
      <td>
        {renderStatus()}
      </td>
    );
  };

  const headerCell = (title) => {
    return (
      <td className='column_header'>{title}</td>
    );
  };

  return (
    <div className='container'>
      <div className='grid' id='left_grid' >
        <h3>Estes</h3>
        <Grid
          data={unitsData.filter(unit => estesUnits.includes(unit.propertyID))}
          style={{ height: 'auto', width: '100%' }}
        >
          <GridColumn
            field='name'
            title='Unit'
            width={'auto'}
            headerCell={() => headerCell('Unit')}
            className='unit_grid'
          />
          <GridColumn
            field='checkOutReservation'
            title='Daily Status'
            cell={dailyStatusCell}
            width={'auto'}
            headerCell={() => headerCell('Daily')}

          />
          <GridColumn
            field='cleaningStatus'
            title='Clean Status'
            cell={cleaningCell}
            width={'auto'}
            headerCell={() => headerCell('Clean')}

          />
          <GridColumn
            field='checkedIn'
            title='Arrival Status'
            cell={arrivalCell}
            width={'auto'}
            headerCell={() => headerCell('Arrival')}

          />
        </Grid>
      </div>
      <div className='grid' id='left_grid' >
        <h3>SL</h3>
        <Grid
          data={unitsData.filter(unit => slUnits.includes(unit.propertyID))}
          style={{ height: 'auto', width: '100%' }}
        >
          <GridColumn
            field='name'
            title='Unit'
            width={'auto'}
            headerCell={() => headerCell('Unit')}
            className='unit_grid'
          />
          <GridColumn
            field='checkOutReservation'
            title='Daily Status'
            cell={dailyStatusCell}
            width={'auto'}
            headerCell={() => headerCell('Daily')}

          />
          <GridColumn
            field='cleaningStatus'
            title='Clean Status'
            cell={cleaningCell}
            width={'auto'}
            headerCell={() => headerCell('Clean')}

          />
          <GridColumn
            field='checkedIn'
            title='Arrival Status'
            cell={arrivalCell}
            width={'auto'}
            headerCell={() => headerCell('Arrival')}

          />
        </Grid>
      </div>
    </div>
  );
}

export default QuickViewView;
