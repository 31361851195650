import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@progress/kendo-react-buttons'
import {useSelector} from 'react-redux';

import { signOut, clearLocation } from 'state/actions'

import createDispatchedActions from 'utils/createDispatchedActions'
import style from '../SignIn/_SignIn.module.scss'
import logo from '../../assets/HappyFamilyRentals_Logo.png'
import L from '../../common/get-localized-string';

function SignOutContainer(props) {

  const {language} = useSelector((state) => ({
    language: state.language,
  }));

  /* redux actions, wrapped in dispatch */
  const actions = createDispatchedActions(signOut)
  const dispatch = useDispatch()

  let redirectTimeout

  /* lifecycle methods */
  useEffect(
    // on mount
    () => {
      dispatch(clearLocation())
      actions.signOut()

      redirectTimeout = setTimeout(() => {
        props.history.replace('/sign-in')
      }, 5000)

      return () => {
        clearTimeout(redirectTimeout)
      }
    },
    []
  )

  return (
    <div
      className={style.container}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div className={style.header}>
        <img src={logo} alt="Happy Family Rentals Logo" />
      </div>
      <div className={style.form_container}>
        <p
          style={{
            color: 'white',
            fontWeight: 'bold',
            padding: '10px',
            textAlign: 'center',
          }}
        >
          Thanks for using Happy Family Rentals!
        </p>

        <div className={style.back_sign_in_button_container}>
          <Button
            className={style.back_sign_in_button_btn}
            primary={true}
            style={{ width: '60%' }}
            onClick={() => {
              props.history.replace('/sign-in')
            }}
          >
            {L('goBackToSignIn', language)}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SignOutContainer
