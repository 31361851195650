import * as React from "react";

import { Calendar } from "@progress/kendo-react-dateinputs";

function CustomCalendar(props) {
  return (
    <Calendar
      bottomView="year"
      topView="year"
      value={props.value}
      onChange={props.onChange}
    />
  );
}

export default CustomCalendar;