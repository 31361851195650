import ScheduleView from './Schedule.view';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
/* Components */
const setCleaningStatus = window.firebase.functions().httpsCallable('setCleaningStatus');
const setReservationNotes = window.firebase.functions().httpsCallable('setReservationNotes');
const createCleaningStatusEmail = window.firebase.functions().httpsCallable('createCleaningStatusEmail');
const updateReservationCheckin = window.firebase.functions().httpsCallable('updateReservationCheckin');

function ScheduleContainer(props) {
  const state = useSelector(state => ({
    user: state.user,
    language: state.language,
  }));

  const locales = {
    'en': {
      language: 'en-US',
      locale: 'en',
    },
    'es': {
      language: 'es-ES',
      locale: 'es',
    },
  };
  const locale = /es/.test(window.navigator.language) ? locales.es : locales.en;


  const [ showNotesDialogue, setShowNotesDialogue ] = useState(false);
  const [ unitForNotes, setUnitForNotes ] = useState(null);
  const [ units, setUnits ] = useState([]);
  const [ region, setRegion ] = useState('All');
  const [ filteredUnits, setFilteredUnits ] = useState(units);
  const [ loading, setLoading ] = useState(true);
  const [ reservationDate, setReservationDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ cleanStatus, setCleanStatus ] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unitsRef = window.firebase.firestore().collection('units')
      .where('property_id', '>=', 0);

    const unitsObserver = unitsRef.onSnapshot((snapshot) => {
      const unitsLoop = [];
      for (let doc of snapshot.docs) {
        doc = doc.data();
        unitsLoop.push(doc);
      }
      setUnits([ ...unitsLoop ]);

      const checkOutResRef = window.firebase.firestore().collectionGroup('reservations')
        .where('end_date', '==', reservationDate);
      checkOutResRef.get().then((snapshot) => {
        for (let doc of snapshot.docs) {
          doc = doc.data();
          if (doc.status === 'accepted' || doc.status === 'BOOKING') {
            for (const unit of unitsLoop) {
              if (unit.property_id === doc.listing.property_id) {
                unit.checkOutReservation = doc;
              }
            }
            setUnits([ ...unitsLoop ]);
          }
        }
      });

      const checkInResRef = window.firebase.firestore().collectionGroup('reservations')
        .where('start_date', '==', reservationDate);
      checkInResRef.get().then((snapshot) => {
        for (let doc of snapshot.docs) {
          doc = doc.data();
          if (doc.status === 'accepted' || doc.status === 'BOOKING') {
            for (const unit of unitsLoop) {
              if (unit.property_id === doc.listing.property_id) {
                unit.checkInReservation = doc;
              }
            }
            setUnits([ ...unitsLoop ]);
          }
        }
      });

      const formattedDate = new Date(reservationDate).getTime();
      const occupiedResRef = window.firebase.firestore().collectionGroup('reservations')
        .where('checkout_time', '>', formattedDate);
      occupiedResRef.get().then((snapshot) => {
        for (let doc of snapshot.docs) {
          doc = doc.data();
          if (moment(doc.start_date).isBefore(reservationDate, 'day') && moment(doc.end_date).isAfter(reservationDate, 'day') && (doc.status === 'accepted' || doc.status === 'BOOKING')) {
            for (const unit of unitsLoop) {
              if (unit.property_id === doc.listing.property_id) {
                unit.occupied = true;
              }
            }
            setUnits([ ...unitsLoop ]);
          }
        }
        setLoading(false);
      });
    });
  }, [ reservationDate ]);

  let isToday = true;
  if (!moment(Date.now()).isSame(moment(reservationDate).format('YYYY-MM-DD'), 'day')) {
    isToday = false;
  }
  useEffect(() => {
    const filterByRegion = () => {
      if (region === 'All') {
        return units;
      }
      return units.filter((unit) => {
        return unit.region === region;
      });
    };

    const sortUnits = () => {
      const filteredByRegion = filterByRegion();
      // do this sort if user is looking at current day
      if (isToday) {
        for (const unit of filteredByRegion) {
          if (unit.checkInReservation && unit.checkOutReservation) {
            // back-to-back is highest priority - so sort order is 1
            unit.sortOrder = 1;
          } else if ((unit.cleaning_status === 'dirty' || unit.cleaning_status === 'in progress') && unit.checkInReservation && !unit.checked_in) {
            // dirty w/ upcoming check-in is next highest priority
            unit.sortOrder = 2;
          } else if ((unit.cleaning_status === 'dirty' || unit.cleaning_status === 'in progress') && unit.checkInReservation && unit.checked_in) {
            // dirty w/ upcoming check-in is next highest priority
            unit.sortOrder = 2.25;
          } else if ((unit.cleaning_status === 'dirty' || unit.cleaning_status === 'in progress' || unit.cleaning_status === 'clean') && unit.checkInReservation) {
            // dirty w/ upcoming check-in is next highest priority
            unit.sortOrder = 2.5;
          }
          else if ((unit.cleaning_status === 'dirty' || unit.cleaning_status === 'in progress') && unit.checkOutReservation) {
            // dirty w/ upcoming check-out is next highest priority
            unit.sortOrder = 3;
          } else {
            unit.sortOrder = 4;
          }
        }
      } else {
        // user is looking at future or past date so show anything with a reservation, w/ back-to-back at top
        for (const unit of filteredByRegion) {
          if (unit.checkInReservation && unit.checkOutReservation) {
            unit.sortOrder = 1;
          } else if (unit.checkInReservation || unit.checkOutReservation) {
            unit.sortOrder = 2;
          } else if (unit.occupied) {
            unit.sortOrder = 3;
          } else {
            unit.sortOrder = 4;
          }
        }
      }
      // eslint-disable-next-line id-length
      return filteredByRegion.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
    };
    setFilteredUnits(sortUnits());
  }, [ region, units ]);

  /* container methods */
  const methods = {
    /* add methods if needed */
    handleShowNotes(unit) {
      setUnitForNotes(unit);
      setShowNotesDialogue(true);
    },

    async handleSubmitNotes(notes, noteStatusArray) {
      setShowNotesDialogue(false);
      setLoading(true);
      const newCleaningDetails = {
        cleaning_status: 'clean',
        property_id: unitForNotes.property_id.toString(),

      };
      return await window.firebase.firestore().doc(`units/${unitForNotes.property_id.toString()}`)
        .get()
        .then(async (doc) => {
          if (doc.data().cleaning_status === 'clean') {
            console.log('unit has already been cleaned, attempted to submit clean twice:');
            return;
          }
          console.log('unit is not clean, proceeding with submission...');
          await setCleaningStatus(newCleaningDetails);

          if (unitForNotes.checkInReservation) {
            console.log('check in exists!!');
            const timezone = unitForNotes.region === 'Estes' ? 'America/Denver' : 'America/New_York';
            const newCheckin = moment(moment().tz(timezone)).utc(true)
              .valueOf();
            const {
              property_id,
              checkInReservation,
              name,
              region,
            } = unitForNotes;
        
            const newCheckinData = {
              checkInReservation,
              unitID: property_id,
              checkin_time: moment(newCheckin).valueOf(),
              unitName: name,
              region,
            };
        
            const exemptionExists = await window.firebase.firestore().collection('units')
              .doc(property_id.toString())
              .get()
              .then(async (unitDoc) => {
                const currentUnit = unitDoc.data();
                const exemptedDates = [];
                const sdExemptions = currentUnit.sdExemptions || [];
        
                sdExemptions.forEach((exemption) => {
                  exemptedDates.push(exemption.date);
                });
                if (exemptedDates.includes(moment().format('YYYY-MM-DD'))) {
                  return true;
                } 
                return false;
              });

            if (!exemptionExists) {
              console.log('updating reservation checkin ****');
              await updateReservationCheckin(newCheckinData);
            } 
          }
          
          if (unitForNotes.checkOutReservation) {
            await setReservationNotes({
              notes,
              noteStatusArray,
              code: unitForNotes.checkOutReservation.code,
              property_id: unitForNotes.property_id.toString(),
            });
            console.log('CALLING 1');
            return await createCleaningStatusEmail({
              first_name: unitForNotes.checkOutReservation.guest.first_name,
              last_name: unitForNotes.checkOutReservation.guest.last_name,
              start_date: unitForNotes.checkOutReservation.start_date,
              end_date: unitForNotes.checkOutReservation.end_date,
              unit_name: unitForNotes.name,
              notes,
              noteStatusArray,
            })
              .then(() => {
                setCleanStatus(true);
                setLoading(false);
                return;
              })
              .catch((e) => { 
                console.log('error creating email', e);
                setLoading(false);
                return;
              });
          } else if (!unitForNotes.checkOutReservation) {
            // find last reservation for unit prior to today then setReservationNote;
            const todaysDate = moment().format('yyyy-MM-DD');
        
            const currentReservationRef = window.firebase.firestore()
              .collection(`units/${unitForNotes.property_id}/reservations`);
            return await currentReservationRef
              .where('end_date', '<', `${todaysDate}`)
              .where('status', 'in', [ 'accepted', 'BOOKING' ])
              .orderBy('end_date', 'desc')
              .limit(1)
              .get()
              .then(async snapshot => {
                if (snapshot.empty) {
                  console.log('No matching documents.');
                  return;
                }
                const reservation = snapshot.docs[0].data();
                await setReservationNotes({
                  notes,
                  noteStatusArray,
                  code: reservation.code,
                  property_id: unitForNotes.property_id.toString(),
                });
        
                const {
                  guest: {
                    first_name,
                    last_name,
                  },
                  start_date,
                  end_date,
                } = reservation;
                console.log('CALLING 2');
                return await createCleaningStatusEmail({
                  first_name,
                  last_name,
                  start_date,
                  end_date,
                  unit_name: unitForNotes.name,
                  notes,
                  noteStatusArray,
                })
                  .then(() => {
                    setCleanStatus(true);
                    setLoading(false);
                    return;
                  })
                  .catch((e) => { 
                    console.log('error creating email', e);
                    setLoading(false);
                    return;
                  });
              });
          }
        });
    },
  };

  return (
    <ScheduleView
      /* Data */
      loading={loading}
      units={filteredUnits}
      status={status}
      reservationDate={reservationDate}
      locale={locale}
      showNotesDialogue={showNotesDialogue}
      unitForNotes={unitForNotes}
      user={state.user}
      hidden={state.user.user_role !== 'admin'}
      region={region}
      cleanStatus={cleanStatus}
      isToday={isToday}
      language={state.language}
      /* methods */
      setReservationDate={setReservationDate}
      handleShowNotes={methods.handleShowNotes}
      setShowNotesDialogue={setShowNotesDialogue}
      handleSubmitNotes={methods.handleSubmitNotes}
      setRegion={setRegion}
      setCleanStatus={setCleanStatus}
    />
  );
}

export default ScheduleContainer;
