import React, { useState, useRef } from 'react';
import style from './_NotesDialogue.module.scss';
import PropTypes from 'prop-types';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { RadioButton } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import L from '../../../../../common/get-localized-string';

function NotesDialogue(props) {
  const {
    setShowNotesDialogue,
    handleSubmitNotes,
    visible,
    unitForNotes,
    language,
  } = props;

  if (!visible) {
    return null;
  }

  const [ notes, setNotes ] = React.useState('');
  const [ okValue, setOkValue ] = React.useState(false);
  const [ dirtyValue, setDirtyValue ] = React.useState(false);
  const [ damageValue, setDamageValue ] = React.useState(false);
  const [ attentionValue, setAttentionValue ] = React.useState(false);
  const [ error, setError ] = React.useState(false);

  const darkStyle = { backgroundColor: '#406c4a', color: '#fff', margin: '5px 0' };
  const lightStyle = { backgroundColor: '#ffff', color: '#406c4a', margin: '5px 0', border: '1px solid #406c4a' };

  const okButtonStyle = okValue ? lightStyle : darkStyle;
  const dirtyButtonStyle = dirtyValue ? lightStyle : darkStyle;
  const damageButtonStyle = damageValue ? lightStyle : darkStyle;
  const attentionButtonStyle = attentionValue ? lightStyle : darkStyle;

  const statusToTextMap = {
    okValue: L('everythingOk', language),
    dirtyValue: L('guestLeftRoomDirty', language),
    damageValue: L('guestCausedDamage', language),
    attentionValue: L('homeownerNeeded', language),
  };

  const statusEnglishMap = {
    okValue: L('everythingOk', 'en'),
    dirtyValue: L('guestLeftRoomDirty', 'en'),
    damageValue: L('guestCausedDamage', 'en'),
    attentionValue: L('homeownerNeeded', 'en'),
  };

  const statusMap = {
    okValue,
    dirtyValue,
    damageValue,
    attentionValue,
  };

  const handleSelectStatus = (func, status) => {
    setError(false);
    func(!status);
  };

  const handleSubmit = () => {
    if (!okValue && !dirtyValue && !damageValue && !attentionValue) {
      return setError('Please select at least one status from above');
    }

    if ((dirtyValue || damageValue || attentionValue) && !notes) {
      return setError('Please add details in notes');
    }

    const noteStatusArray = Object.keys(statusMap)
      .filter(status => statusMap[status])
      .map(key => statusEnglishMap[key]);

    handleSubmitNotes(notes, noteStatusArray);
    setNotes('');
  };

  const renderError = () => {
    if (!error) {
      return (
        <span className={style.error}>
        </span>
      );
    }
    return (
      <span className={style.error}>
        {error}
      </span>
    );
  };

  return (
    <Dialog
      className={style.container}
      title={`Notes for ${unitForNotes.name}`}
      width={400}
      height={700}
      onClose={() => setShowNotesDialogue(false)}>
      <div className={style.button_container}>
        <Button style={okButtonStyle} onClick={() => handleSelectStatus(setOkValue, okValue)}>{statusToTextMap.okValue}</Button>
        <Button style={dirtyButtonStyle} onClick={() => handleSelectStatus(setDirtyValue, dirtyValue)}>{statusToTextMap.dirtyValue}</Button>
        <Button style={damageButtonStyle} onClick={() => handleSelectStatus(setDamageValue, damageValue)}>{statusToTextMap.damageValue}</Button>
        <Button style={attentionButtonStyle} onClick={() => handleSelectStatus(setAttentionValue, attentionValue)}>{statusToTextMap.attentionValue}</Button>
      </div>
      <p style={{ margin: '25px', textAlign: 'center', fontSize: '12px' }}>Please write additional notes here</p>
      <textarea
        value={notes}
        style={{ width: '100%', height: '30%' }}
        onChange={(e) => setNotes(e.target.value)}/>
      <DialogActionsBar>
        <div className={style.button_container}>
          <br/>
          <Button primary style={{ margin: '0 5px', backgroundColor: '#406c4a' }} onClick={() => handleSubmit()}>Submit</Button>
          <br/>
          <Button style={{ margin: '0 5px', backgroundColor: '#ffff', color: '#333232', border: '1px solid #333232' }} onClick={() => setShowNotesDialogue(false)}>Cancel</Button>
          {renderError()}
          <p style={{ fontSize: '12px' }}>{L('sendPicture', language)}</p>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
}

NotesDialogue.propTypes = {

};

export default NotesDialogue;
