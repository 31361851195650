// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._Error-module__containerWrapper___Ws-Ms{display:flex;flex-direction:column;justify-content:space-between;overflow:scroll}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE{width:100%;padding:5px 10px;max-width:750px;align-self:center}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE ._Error-module__header___FeJYU{display:flex;flex-direction:row;margin:10px;align-items:center;justify-content:center}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE ._Error-module__header___FeJYU *{height:60px}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE p{margin:20px 0;text-align:center}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE ._Error-module__mainContent___1lGEx{display:flex;flex-direction:column;width:100%;text-align:center;align-items:center;flex-grow:1}._Error-module__containerWrapper___Ws-Ms ._Error-module__container___KutRE ._Error-module__mainContent___1lGEx button{height:50px;width:100%;margin:10px 0}._Error-module__containerWrapper___Ws-Ms ._Error-module__footerContent___HoxBu{text-align:center;height:100px;display:flex;justify-content:center;align-items:center;background:black;color:white;font-size:14px}._Error-module__containerWrapper___Ws-Ms ._Error-module__footerContent___HoxBu a{color:white;text-decoration:underline;font-weight:bold}\n", ""]);
// Exports
exports.locals = {
	"containerWrapper": "_Error-module__containerWrapper___Ws-Ms",
	"container": "_Error-module__container___KutRE",
	"header": "_Error-module__header___FeJYU",
	"mainContent": "_Error-module__mainContent___1lGEx",
	"footerContent": "_Error-module__footerContent___HoxBu"
};
module.exports = exports;
