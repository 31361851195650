// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._NotesDialogue-module__container___z9mWt{display:flex;flex-direction:column;flex:1 1}._NotesDialogue-module__container___z9mWt ._NotesDialogue-module__button_container___13pVR{width:100%;display:flex;flex-direction:column;justify-content:space-between}._NotesDialogue-module__container___z9mWt ._NotesDialogue-module__button_container___13pVR ._NotesDialogue-module__button___1yhKC{margin-bottom:3px}._NotesDialogue-module__container___z9mWt ._NotesDialogue-module__error___2cQ1T{color:red;font-size:12px;height:12px;margin-bottom:3px}._NotesDialogue-module__container___z9mWt ._NotesDialogue-module__header_container___3O2zx{display:flex;padding:30px 0}._NotesDialogue-module__container___z9mWt ._NotesDialogue-module__header_container___3O2zx h1{align-self:center;margin:0;text-align:center;font-size:18px}\n", ""]);
// Exports
exports.locals = {
	"container": "_NotesDialogue-module__container___z9mWt",
	"button_container": "_NotesDialogue-module__button_container___13pVR",
	"button": "_NotesDialogue-module__button___1yhKC",
	"error": "_NotesDialogue-module__error___2cQ1T",
	"header_container": "_NotesDialogue-module__header_container___3O2zx"
};
module.exports = exports;
