import React from 'react';

const JolHeaderLogo = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 98.5 21.6"
    xmlSpace="preserve" >
    <style type="text/css">
      { `.st0 { fill:${props.fill} }` }
    </style>
    <g className="st0">
      <path d="M7,14.5c0,1.1-0.4,2-1.1,2.7s-1.6,1.1-2.7,1.1c-0.6,0-1.1-0.1-1.5-0.2L2,15.8c0.3,0.1,0.5,0.1,0.7,0.1c0.1,0,0.3,0,0.5,0
        c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-1V2H7V14.5z" />
      <path d="M12.9,1.9c1.1,0,2,0.4,2.7,1.1c0.7,0.8,1.1,1.7,1.1,2.7v8.7c0,1.1-0.4,2-1.1,2.7c-0.8,0.7-1.7,1.1-2.7,1.1s-2-0.4-2.7-1.1
        c-0.7-0.8-1.1-1.7-1.1-2.7V5.7c0-1.1,0.4-2,1.1-2.7S11.8,1.9,12.9,1.9z M14.2,5.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
        c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v8.8c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
        c0.3-0.3,0.4-0.6,0.4-1V5.6z" />
      <path d="M21.3,18.1h-2.4V2h2.4V18.1z" />
      <path d="M26.1,2l3.2,10.8V2h2.4v16h-2.6L25.8,7.8v10.3h-2.4V2H26.1z" />
      <path d="M38.9,1.9c1.1,0,2,0.4,2.7,1.1c0.7,0.8,1.1,1.7,1.1,2.7v8.7c0,1.1-0.4,2-1.1,2.7c-0.8,0.7-1.7,1.1-2.7,1.1s-2-0.4-2.7-1.1
        c-0.7-0.8-1.1-1.7-1.1-2.7V5.7c0-1.1,0.4-2,1.1-2.7S37.9,1.9,38.9,1.9z M40.2,5.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
        c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v8.8c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
        c0.3-0.3,0.4-0.6,0.4-1V5.6z" />
      <path d="M48.7,18.3c-1.1,0-2-0.4-2.7-1.1c-0.7-0.7-1.1-1.6-1.1-2.7V2h2.4v12.4c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4
        c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1V2h2.5v12.4c0,1.1-0.4,2-1.1,2.7S49.7,18.3,48.7,18.3z" />
      <path d="M58.5,2c1.3,0,2.3,0.4,3,1.1c0.6,0.7,0.8,1.6,0.8,2.7v2.3c0,1.1-0.4,2.1-1.3,2.9l1.8,7h-2.6L58.7,12c-0.1,0-0.1,0-0.2,0
        h-1.4v6h-2.4V2H58.5z M59.9,5.9c0-0.9-0.5-1.4-1.4-1.4h-1.5v5.1h1.5c0.4,0,0.7-0.1,1-0.4s0.4-0.6,0.4-1V5.9z" />
      <path d="M72.7,18.1h-6.6V2h2.4v13.6h4.2V18.1z" />
      <path d="M76.7,18.1h-2.4V2h2.4V18.1z" />
      <path d="M81.6,2l3.2,10.8V2h2.4v16h-2.6L81.3,7.8v10.3h-2.4V2H81.6z" />
      <path d="M89.4,18.1V2h6.9v2.4h-4.5v4.4h3.3v2.4h-3.3v4.4h4.5v2.4H89.4z" />
    </g >
  </svg >
);

export default JolHeaderLogo;
