import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import logo from '../../assets/HappyFamilyRentals_Logo.png';

/* data management */
import PropTypes from 'prop-types';

/* components */
import InputWrapper from 'components/InputWrapper';
import style from './_SignIn.module.scss';
import L from '../../common/get-localized-string';

import { Icon, Input } from 'antd';

/**
 * SignIn view
 * @props {
 *   loading __ "true" when waiting for authentication response
 *   form __ output data & methods from react-hook-form
 *   onSubmit __ handles authentication request/response
 * }
 */
function SignInView(props) {
  const {
    /* data */
    loading,
    alertVisible,
    emailMessage,
    language,
    errorMessage,

    /* utils */
    form,
    formData,

    /* methods */
    onSubmit,
    sendPasswordResetEmail,
  } = props;

  return (
    <div className={style.container}>
      <div className={style.header}>
        <img src={logo} alt="Happy Family Rentals Logo" />
      </div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form_container}
      >
        {/* <label className={style.login_label}>Log In</label> */}
        <InputWrapper
          className={style.input_wrapper}
          name="email"
          placeholder={L('emailAddress', language)}
          component={<Input type={'email'} />}
          form={form}
          containerClassName={style.form_column}
        />

        <InputWrapper
          className={style.input_wrapper}
          name="password"
          placeholder={L('password', language)}
          component={<Input.Password type={'password'} />}
          form={form}
          containerClassName={style.form_column}
        />

        {alertVisible ? (
          <div className={style.error}>
            <p className={style.error_message}>{errorMessage}</p>
            <p className={style.error_message}>
              Please make sure you have an active Happy Family Rental account,
              or call{' '}
              <a
                href={'tel:3033714196'}
                target={'_blank'}
                style={{ color: '#406c4a' }}
              >
                (303) 371-4196
              </a>{' '}
              for support.
            </p>
          </div>
        ) : null}

        <div className={style.submit_button}>
          {loading ? (
            <Icon
              spin
              type="loading"
              style={{ fontSize: 20, color: '#007bff' }}
            />
          ) : (
            <Button
              className={style.submit_button_btn}
              disabled={loading}
              primary={true}
            >
              {L('logIn', language)}
            </Button>
          )}
        </div>
      </form>

      {emailMessage ? (
        <p className={style.email_message}>{emailMessage}</p>
      ) : (
        <p className={style.email_message}>
          {L('forgotPassword1', language)}
          <a
            className={style.forgot_password}
            href="#"
            onClick={() => sendPasswordResetEmail(formData.email)}
          >
          {L('here', language)}
          </a>{' '}
          {L('forgotPassword2', language)}
        </p>
      )}
    </div>
  );
}

SignInView.propTypes = {
  /* data */
  loading: PropTypes.bool.isRequired,

  /* utils */
  form: PropTypes.object.isRequired,

  /* methods */
  onSubmit: PropTypes.func.isRequired,
};

export default SignInView;
