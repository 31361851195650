import React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import ScheduleCard from '../../../components/ScheduleCard';
import { NotesDialogue } from './components/NotesDialogue';
import moment from 'moment';
import { Icon, Switch } from 'antd';
/* Components */
import style from './_Schedule.module.scss';
import PropTypes from 'prop-types';
import esMessages from 'common/es.json';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import L from '../../../common/get-localized-string';

load(
  likelySubtags,
  currencyData,
  weekData, numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

loadMessages(esMessages, 'es-ES');

function ScheduleView(props) {
  const {
    /* data */
    loading,
    units,
    locale,
    showNotesDialogue,
    handleSubmitNotes,
    unitForNotes,
    user,
    hidden,
    region,
    cleanStatus,
    isToday,
    language,

    /* methods */
    setReservationDate,
    reservationDate,
    setShowNotesDialogue,
    handleShowNotes,
    setRegion,
    setCleanStatus,
  } = props;
  
  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 200 }}>
        <Icon
          spin
          type='loading'
          style={{ fontSize: 100, color: '#406c4a' }}
        />
      </div>
    );
  }

  const checkReservationCount = (units) => {
    let reservationCount = 0;
    for (let i = 0; i < units.length; i++) {
      if (units[i].checkOutReservation) {
        reservationCount++;
      }
    }
    return reservationCount;
  };

  const renderUnits = () => {
    let unitsByStatus = [];
    if (cleanStatus && isToday) {
      unitsByStatus = units.filter((unit) => {
        return unit.cleaning_status === 'clean' || (unit.checked_in && unit.checkInReservation);
      });
    } else if (!cleanStatus && isToday) {
      unitsByStatus = units.filter((unit) => {
        return (unit.cleaning_status === 'dirty' && !unit.checked_in) || (unit.cleaning_status === 'in progress' && !unit.checked_in);
      });
    } else {
      unitsByStatus = units;
    }

    let unitsInRegion = [];
    if (user.user_role !== 'admin') {
      unitsInRegion = unitsByStatus.filter((unit) => {
        return unit.region === user.user_region;
      });
    } else {
      unitsInRegion = unitsByStatus;
    }

    if (!checkReservationCount(unitsInRegion) && !isToday) {
      return <p className={style.no_checkouts}>{L('noCheckouts', language)}</p>;
    }

    if (unitsInRegion.length === 0) {
      return <p className={style.no_checkouts}>{L('allUnitsClean', language)}</p>;
    }

    const cleanCount = unitsInRegion.filter(unit => unit.cleaning_status === 'clean').length;
    if (cleanCount === 0 && cleanStatus) {
      return <p className={style.no_checkouts}>No Clean Units To Display</p>;
    }

    return unitsInRegion.map(unit => {
      let backToBack = false;
      if (unit.checkInReservation && unit.checkOutReservation) {
        backToBack = true;
      }

      return (
        <ScheduleCard
          key={unit.property_id}
          isToday={isToday}
          backToBack={backToBack}
          hidden={unit.cleaning_status === 'clean' && !unit.checkInReservation}
          occupied={unit.occupied}
          admin={user.user_role === 'admin'}
          unit={unit}
          handleShowNotes={handleShowNotes}
          setShowNotesDialogue
          language={language}
        />
      );
    });
  };

  const formatDate = (unformattedDate) => {
    const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!unformattedDate || !unformattedDate.match(pattern)) {
      return null;
    }
    return unformattedDate.replace(pattern, '$2/$3/$1');
  };

  const handleDateIncrement = (direction) => {
    if (direction === 'forward') {
      const nextDay = moment(reservationDate).add(1, 'days');
      const nextDayFormatted = nextDay.format('YYYY-MM-DD');
      setReservationDate(nextDayFormatted);
    } else if (direction === 'back') {
      const prevDay = moment(reservationDate).subtract(1, 'days');
      const prevDayFormatted = prevDay.format('YYYY-MM-DD');
      setReservationDate(prevDayFormatted);
    }
  };

  return (
    <div className={style.container}>
      <LocalizationProvider language={locale.language}>
        <IntlProvider locale={locale.locale}>
          <div className={style.header_container}>
            <button className={style.date_button} style={{ marginRight: '10px' }} onClick={() => handleDateIncrement('back')}>
              <span className=" k-icon k-i-arrow-chevron-left"></span >
            </button>
            <DatePicker
              className={style.date_picker}
              defaultValue={new Date(formatDate(reservationDate))}
              value={new Date(formatDate(reservationDate))}
              format={{
                skeleton: 'MMMdEy',
              }}
              onChange={(e) => setReservationDate(e.target.value.toISOString()
                .slice(0, 10))}
            />
            <button className={style.date_button} style={{ marginLeft: '10px' }} onClick={() => handleDateIncrement('forward')}>
              <span className=" k-icon k-i-arrow-chevron-right"></span >
            </button>
          </div>
          <div className={style.filter_regions_container}>
            <div className={style.filter_regions} >
              <Switch className={style.toggle} style={isToday ? { width: '5rem' } : { display: 'none' }}checkedChildren={L('dirty', language)} unCheckedChildren={L('clean', language)} checked={!cleanStatus ? true : false} onChange={() => setCleanStatus(!cleanStatus)}/>
              <button className={region === 'Estes' ? style.filter_regions_btn_active : style.filter_regions_btn} style={hidden ? { display: 'none' } : null} onClick={() => setRegion('Estes')}>Estes Park</button>
              <button className={region === 'Florida' ? style.filter_regions_btn_active : style.filter_regions_btn} style={hidden ? { display: 'none' } : null} onClick={() => setRegion('Florida')}>Florida</button>
              <button className={region === 'All' ? style.filter_regions_btn_active : style.filter_regions_btn} style={hidden ? { display: 'none' } : null} onClick={() => setRegion('All')}>{L('seeAll', language)}</button>
            </div>
          </div>
          <div className={style.card_container}>
            {renderUnits()}
            <NotesDialogue
              setShowNotesDialogue={setShowNotesDialogue}
              handleSubmitNotes={handleSubmitNotes}
              visible={showNotesDialogue}
              unitForNotes={unitForNotes}
              language={language}
            />
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
ScheduleView.propTypes = {
  /* data */
  loading: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
  locale: PropTypes.object.isRequired,
  /* methods */
  setReservationDate: PropTypes.func.isRequired,
};
export default ScheduleView;
