import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import AuthHawk from 'HOC/AuthHawk';

import {
  SignIn,
  SignOut,
  Error,
  MainPage,
} from './lazy_routes';

export default (
  <Switch>

    <Route
      exact
      path="/"
      component={AuthHawk(MainPage)}
    />

    <Route
      exact
      path="/sign-in"
      component={SignIn}
    />

    <Route
      exact
      path="/sign-out"
      component={SignOut}
    />

    <Route
      path="/mainpage"
      component={AuthHawk(MainPage)}
    />

    <Route component={Error} />

  </Switch>
);
